<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div
      class="auth-inner py-2"
    >
      <b-card
        :img-src="require('@/assets/images/banner/empty.png')"
        class="card-profile"
      >
        <b-link
          :to="{ path: '/' }"
        >
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">

              <b-avatar
                size="114"
                :src="appLogoImage"
              />
            </div>
          </div>
          <h3><span class="text-primary">BEE</span> SUMMER <span class="text-primary">夏</span>蜂</h3>
          <h6 class="text-muted">
            beesummer.com
          </h6>
          <b-badge
            class="profile-badge"
            variant="light-primary"
          >
            @beesummer
          </b-badge>
        </b-link>
        <hr class="mb-2">

        <!-- follower projects rank -->
        <!-- <div class="d-flex justify-content-between align-items-center">
          <div>
            <h6 class="text-muted font-weight-bolder">
              Followers
            </h6>
            <h3 class="mb-0">
              10.3k
            </h3>
          </div>
          <div>
            <h6 class="text-muted font-weight-bolder">
              Projects
            </h6>
            <h3 class="mb-0">
              156
            </h3>
          </div>
          <div>
            <h6 class="text-muted font-weight-bolder">
              Rank
            </h6>
            <h3 class="mb-0">
              23
            </h3>
          </div>
        </div> -->
        <!--/ follower projects rank -->
        <b-row class="m-1">
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="success"
              href="https://lin.ee/9j8f6jh"
            >
              LINE
            </b-button>
          </b-col>
        </b-row>
        <b-row class="m-1">
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="secondary"
              href="https://t.me/+b2sMe0PZDCZiOGFl"
            >
              Telegram
            </b-button>
          </b-col>
        </b-row>
        <b-row class="m-1">
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="primary"
              href="https://firebasestorage.googleapis.com/v0/b/beesummer.appspot.com/o/sales%2F11_10_sales_kit.pdf?alt=media&token=33c96bd9-415f-4c9c-8758-fb66541bc482"
            >
              產品說明檔案
            </b-button>
          </b-col>
        </b-row>
        <b-row class="m-1">
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="primary"
              href="https://docs.google.com/forms/d/e/1FAIpQLSe47fDwetxMw-2AogOsBCj7KfALZTwdwVLYb4yi1SfFuWQfiA/viewform"
            >
              功能需求及問題回報
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BAvatar, BBadge, BButton, BLink, BRow, BCol,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCard,
    BBadge,
    BButton,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      appLogoImage: $themeConfig.app.appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style>
  .btn-success, .btn-success:hover, .btn-success:active, .btn-success:visited {
    background-color: #00b900 !important;
    border-color: #00b900 !important;
  }
  .dark-layout .btn.btn-success {
    color: white !important;
  }

  .btn-secondary, .btn-secondary:hover, .btn-secondary:hover, .btn-secondary:active, .btn-secondary:visited {
    background-color: #2ca2c6 !important;
    border-color: #2ca2c6 !important;
  }
  .dark-layout .btn.btn-secondary {
    color: white !important;
  }
</style>
